<template>
  <div class="login">
    <div class="bgi">
      <img
        src="https://files.sheguvip.com/pre/resource/static/png/20220408/1512245876033196032/bg_log_in@2x.png"
      />
    </div>
    <div class="banner">
      <img
        src="https://files.sheguvip.com/pre/resource/static/png/20220412/1513762647621898240/logo／complete.png"
      />
      <a-form-model
        ref="rulesForm"
        :model="form"
        :rules="rules"
        :label-col="{ span: 2 }"
        :wrapper-col="{ span: 24 }"
      >
        <a-form-model-item
          ref="email"
          label="账号"
          prop="email"
          labelAlign="left"
        >
          <a-input
            v-model="form.email"
            :maxLength="32"
            placeholder="请输入账号"
            class="my_input"
            @pressEnter="loginClick(form)"
          >
            <a-icon
              slot="prefix"
              type="user"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-model-item>
        <a-form-model-item ref="pwd" label="密码" prop="pwd" labelAlign="left">
          <a-input-password
            type="password"
            v-model="form.pwd"
            :maxLength="32"
            placeholder="请输入密码"
            class="my_input"
            @pressEnter="loginClick(form)"
          >
            <a-icon
              slot="prefix"
              type="lock"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input-password>
        </a-form-model-item>
        <a-button
          type="primary"
          :loading="loginLoading"
          :disabled="loginLoading"
          @click="loginClick(form)"
        >
          登录
        </a-button>
      </a-form-model>
    </div>
    <div class="copyright">
      ©此网站由广州设谷科技有限公司进行维护 粤ICP备2022021290号-1
    </div>
  </div>
</template>

<script>
import { JSEncrypt } from "jsencrypt";
import { message } from "ant-design-vue";
import { UserLoginApi, DictionaryApi } from "@/request/api/login";
export default {
  created() {
    localStorage.clear();
    sessionStorage.clear();
  },
  data() {
    return {
      loginLoading: false,
      key: `MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBANN+W0eAyn+CIiK8U4M2l6aRc5CpspSc5E8/oRfEhJTwVrxfE6A4D1OJf+71K+/OdFQ0B8uy/8dAgkv15BDWS9feeijndOcSYXbEWp5TKrQOxMJWuLcZvIFSKGEO/lItJAiCwgGDSB4CfyGTLTc4zTrRmJrxXQh/7pgKS26PXlM5AgMBAAECgYBmcnTA5DAzJ9RlQxipHM7FDvwN/UXWG1G02butCbK3etxpDx0wpTc3XgmstPJ9jWJoT3PfRmwEFsmHBitdxJCjtTj1DHZlB14/X6BWInh/GdamvxpI9Hqq6hNnE4FuMF/ZjR0PMKU46uqqsxaAwXaky2YysIaKd5+fyEq9upJYAQJBAPGXCpMP8cf8Kv/pWX7MWC9on/tar4jGciGR0lArocYE8DAZD687rypdhA4yxS4wZhmtaYnsmS71sGobUkIrQLkCQQDgG8IEgCuUVRTR+ehHzs6OXfOzVlom02OU6v6ePYYFHHd8jjv0fqvW3EzZXdPbr1gEKfqH3NsAccG94BlrkWaBAkB7JwO88+PMjj9dNRbqDEDn6e2jtBbHImILusn7FELjLqFB44SbT1F7xzAKDNd3NDTX4g5qwLFVvuivLj5AfMwBAkEApANMk/SlcDJwQVfvKhZtTv3ta7Nn2QBd4c+1tmN6YOU7gTmrIT1PT7j8uRwU/7Ms7Cc4xhPZZ1LZoreOgw3vgQJAW90hiqz9Qa6x7SYBOnWFZSPOLhKkQPF5M3m6xEbQarF+7eKjfU077MArQx6jQOOC5ljh9LjHYa9AsK8p6QlEjg==`,
      form: {
        email: "",
        pwd: "",
      },
      rules: {
        email: [{ validator: this.validateEmail, trigger: "blur" }],
        pwd: [{ validator: this.validatePwd, trigger: "blur" }],
      },
    };
  },
  methods: {
    // 账号的校验规则
    validateEmail(rule, value, callback) {
      let reg =
        /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/;
      if (value === "") {
        callback("输入的内容不能为空");
      } else if (!reg.test(value)) {
        callback("请输入正确的邮箱地址");
      } else {
        callback();
      }
    },
    // 密码校验规则
    validatePwd(rule, value, callback) {
      // let reg = /^(?=.*[a-zA-Z]+)(?=.*[0-9]+)[a-zA-Z0-9]+$/;
      if (value === "") {
        callback("输入的内容不能为空");
      } else {
        callback();
      }
    },
    // 密码加密
    encryption(msg, key) {
      let encryptor = new JSEncrypt(); // 创建加密对象实例
      //之前ssl生成的公钥，复制的时候要小心不要有空格
      encryptor.setPublicKey(key); //设置公钥
      let rsaPassWord = encryptor.encrypt(msg); // 对内容进行加密
      return rsaPassWord;
    },
    loginClick(from) {
      this.loginLoading = true;
      this.$refs["rulesForm"].validate((result) => {
        if (result) {
          // 验证通过发送请求
          UserLoginApi({
            username: from.email,
            password: this.encryption(from.pwd, this.key),
          }).then(({ code, data }) => {
            if (code == 200) {
              localStorage.setItem("token", data.token);
              localStorage.setItem("userName", data.userName);
              localStorage.setItem("headUrl", data.headUrl);
              localStorage.setItem("powerListArr", JSON.stringify(data.menus));
              localStorage.setItem("arrayShop", JSON.stringify(data.arrayShop));
              if (data.menus.length > 0) {
                message.success("登录成功", 3);
                this.$router.push("/home");
              } else {
                message.error("您没有访问权限,请换账号登录", 3);
              }
              // 全局数据字典
              DictionaryApi().then(({ code, data }) => {
                if (code == 200) {
                  localStorage.setItem("dictionaryObj", JSON.stringify(data));
                }
              });
            }
            setTimeout(() => {
              this.loginLoading = false;
            }, 3000);
          });
        } else {
          this.loginLoading = false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  position: relative;
  .bgi {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .banner {
    position: absolute;
    top: 15%;
    left: calc(50% - 245px);
    z-index: 9999;
    img {
      width: 490px;
      height: 250px;
    }
  }
}
.my_input {
  width: 420px;
  height: 60px;
  line-height: 60px;
  background: rgba(255, 255, 255, 0);
  opacity: 0.5;
  margin-left: 30px;
  border-radius: 4px;
  ::-webkit-input-placeholder {
    /* WebKit browsers */
    background: rgba(255, 255, 255, 0);
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    background: rgba(255, 255, 255, 0);
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    background: rgba(255, 255, 255, 0);
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    background: rgba(255, 255, 255, 0);
  }
}
.ant-btn {
  width: 420px;
  height: 60px;
  margin-left: 30px;
  // background: linear-gradient(135deg, #ffe1b9 0%, #e5bb84 100%);
  // color: #222;
  border-radius: 4px;
  font-weight: 600;
}
.copyright {
  width: 600px;
  text-align: center;
  position: absolute;
  bottom: 30px;
  left: calc(50% - 300px);
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
}
.ant-form {
  margin-top: 70px;
}
.ant-form-item-label {
  font-weight: 700;
}
.ant-btn-primary {
  border-color: none;
  margin-top: 20px;
}
/deep/.ant-form-item-label {
  display: none;
}
/deep/.ant-form-explain {
  margin-left: 30px;
}
/deep/.anticon {
  color: #222 !important;
  font-size: 20px !important;
}
/deep/.ant-input {
  height: 60px;
  padding-left: 40px !important;
  // color: #fff;
  // background-color: #fff;
}
/deep/.ant-input-affix-wrapper .ant-input {
  border: 1px solid #fff;
  background-color: #fff !important;
}
/depp/.has-error .ant-input-affix-wrapper .ant-input,
.has-error .ant-input-affix-wrapper .ant-input:hover {
  background-color: #fff !important;
}
</style>
